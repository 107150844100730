
import { subscribe } from "@/composables/getSubscriptions";
import { useCounterStore } from "@/stores/subStore";
import { storeToRefs } from "pinia";
import { defineComponent, ref, watchEffect } from "vue";
import { onBeforeRouteLeave } from "vue-router";

export default defineComponent({
  name: "MainSubscription",
  setup() {
    const subs = ref(null);
    const phoneNumber = ref("");
    let mobile = "";

    watchEffect(() => {
      mobile = phoneNumber.value;
    });

    const store = useCounterStore();
    const { name, id, description } = storeToRefs(store);
    const { resetSubscription } = store;
    const { error, loading, handleSubscribe } = subscribe();

    onBeforeRouteLeave((to) => {
      if (to.path === "/") {
        resetSubscription();
      }
    });

    return { subs, error, loading, handleSubscribe, phoneNumber, name, id, description };
  },
});
