import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-primary bg-white overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_HeroSection = _resolveComponent("HeroSection")!
  const _component_MainSubscription = _resolveComponent("MainSubscription")!
  const _component_BodyComponent = _resolveComponent("BodyComponent")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_NavBar),
    _createVNode(_component_HeroSection),
    _createVNode(_component_MainSubscription),
    _createVNode(_component_BodyComponent),
    _createVNode(_component_FooterSection)
  ]))
}