import { Subs } from "@/models/Subscription";
import axios from "axios";
import { ref } from "vue";

function getSubscriptions() {
  const subscriptions = ref();
  const error = ref(null);
  const loading = ref(false);

  const fetchSubscriptions = () => {
    loading.value = true;
    axios<Subs[] | []>({
      method: "GET",
      url: "https://api.orctic.co.ke/api/v1/website/get-offers",
    })
      .then((res) => {
        loading.value = false;
        subscriptions.value = res.data;
      })
      .catch((err) => {
        loading.value = false;
        error.value = err.response.data || err.message;
      });
  };

  return { subscriptions, error, fetchSubscriptions, loading };
}
function subscribe() {
  const error = ref(null);
  const loading = ref(false);

  const handleSubscribe = (mobile: string, offerID: string) => {
    loading.value = true;
    axios({
      method: "POST",
      url: "https://api.orctic.co.ke/api/v1/sdp/create-new-subscriber",
      data: { mobile, offerID },
    })
      .then(() => {
        loading.value = false;
      })
      .catch((err) => {
        loading.value = false;
        error.value = err.response.data || err.message;
      });
  };

  return { error, handleSubscribe, loading };
}

export { getSubscriptions, subscribe };
