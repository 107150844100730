
import { defineComponent } from "vue";

import HeroSection from "./Banner.vue";
import BodyComponent from "./Body.vue";
import FooterSection from "./Footer.vue";
import MainSubscription from "./MainSubscription.vue";
import NavBar from "./NavBar.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    NavBar,
    HeroSection,
    BodyComponent,
    MainSubscription,
    FooterSection,
  },
});
