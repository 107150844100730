
import { getSubscriptions } from "@/composables/getSubscriptions";
import { defineComponent } from "vue";
import SubscribeComponent from "./SubscribeComponent.vue";

export default defineComponent({
  name: "BodyComponent",
  components: {
    SubscribeComponent,
  },
  setup() {
    const { error, fetchSubscriptions, subscriptions, loading } = getSubscriptions();
    fetchSubscriptions();

    return { error, subscriptions, loading };
  },
  data() {
    return {
      // subscriptions: [
      //   { title: "Daily Bible Verses" },
      //   { title: "Sports Betting Tips" },
      //   { title: "Subscribe to Health Tips" },
      //   { title: "Traffic Updates" },
      //   { title: "Weather Updates" },
      //   { title: "Special Offers" },
      //   { title: "Tenders & Contracts" },
      //   { title: "Job Alerts" },
      // ],
      count: 2,
    };
  },
  methods: {
    viewMore() {
      this.count = this.subscriptions.length;
    },
    showLess() {
      this.count = 3;
    },
  },
});
