
import { subscribe } from "@/composables/getSubscriptions";
import { useCounterStore } from "@/stores/subStore";
import { defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
  name: "SubscribeComponent",
  props: ["sub"],
  setup(props: any) {
    const subs = ref(props.sub);
    const { error, loading, handleSubscribe } = subscribe();
    const phoneNumber = ref("");
    let mobile = "";

    watchEffect(() => {
      mobile = phoneNumber.value;
    });

    const store = useCounterStore();
    const { setSubscription } = store;

    function handlePush() {
      // @ts-ignore
      this.$router.push({
        name: "subscription",
        params: { name: subs.value.name },
      });
      setSubscription(subs.value.id, subs.value.name, subs.value.description);
    }

    return { subs, error, loading, handleSubscribe, phoneNumber, handlePush };
  },
});
