import { defineStore } from "pinia";

export const useCounterStore = defineStore("subscriptions", {
  state: () => ({ id: "", name: "", description: "" }),
  actions: {
    setSubscription(id: string, name: string, description: string) {
      this.id = id;
      this.name = name;
      this.description = description;
    },
    resetSubscription() {
      this.id = "";
      this.name = "";
      this.description = "";
    },
  },
});

export default useCounterStore;
