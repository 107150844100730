
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
  data: () => ({ showNav: false }),
  mounted() {
    if (!this.isMobile()) {
      this.showNav = true;
    }
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
    isMobile(): boolean {
      return window.innerWidth <= 760;
    },
  },
});
